import { graphql, useStaticQuery } from "gatsby";
import React from "react";

import Img from "gatsby-image";
import Heading from "../Heading";
import Inner from "../Inner";
import { NodesInterface } from "../../interfaces/blogPost";
import { TransistorShowInterface } from "../../interfaces/podcasts";
import { ChildImageSharp } from "../../interfaces/images";
import { SiteInterface } from "../../pages";
import { homeHeroStyles as styles } from "./styles";

interface HomeHeroQuery {
  site: Omit<SiteInterface, "keywords">;
  arrowDown: ChildImageSharp;
  heroImageSeries2: ChildImageSharp;
  shows: NodesInterface<TransistorShowInterface>;
}

const HomeHero: React.FC<{}> = () => {
  const data: HomeHeroQuery = useStaticQuery(graphql`
    {
      site: sanitySiteSettings {
        title
        description
      }
      heroImageSeries2: file(
        relativePath: { eq: "wmdj_hero_image_series2.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      arrowDown: file(relativePath: { eq: "arrowDown.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      shows: allTransistorShow {
        nodes {
          attributes {
            id
            multiple_seasons
          }
        }
      }
    }
  `);
  const { site } = data;
  const content =
    site.description ||
    "Busting taboos about leaks & lumps after childbirth. A kick ass podcast about pelvic pain, prolapse and incontinence.";

  const getHeroImage = () => {
    const show = data.shows.nodes.find(
      s => s.attributes.id === process.env.GATSBY_SHOW_ID
    );
    if (!data?.shows || !show) {
      return null;
    }

    return (
      <Img
        fluid={data?.heroImageSeries2.childImageSharp.fluid}
        fadeIn={false}
        loading="eager"
      />
    );
  };
  return (
    <Inner>
      <div css={styles.container}>
        <div css={styles.content}>
          <Heading headingLevel="h1" styles={styles.header}>
            Why Mums Don't Jump
          </Heading>
          <p>{content}</p>
          <Img
            css={styles.arrow}
            fluid={data?.arrowDown.childImageSharp.fluid}
            alt="Why Mum's Don't Jump the Podcast - Women interested in pelvic health"
            fadeIn={false}
            loading="eager"
          />
        </div>
        <div>{getHeroImage()} </div>
      </div>
    </Inner>
  );
};

export default HomeHero;
