import { EmotionStyles } from "../../interfaces/emotion";
import { breakpointMixins } from "../../styles/breakpoints";
import { variables } from "../../styles/variables";
import { textStyles } from "../../styles/type";

const { spacing } = variables;
export const homeHeroStyles: EmotionStyles = {
  container: {
    paddingTop: spacing.small,
    marginBottom: spacing.large,
    [breakpointMixins.md]: {
      display: "grid",
      gridGap: spacing.medium,
      gridTemplateColumns: "minMax(200px, 350px) 1fr"
    },
    [breakpointMixins.lg]: {
      gridGap: spacing.large
    }
  },
  header: {
    margin: `0 0 ${spacing.large}`,
    lineHeight: "100%",
    maxWidth: 350,
    [breakpointMixins.lg]: {
      maxWidth: 200,
      fontSize: textStyles.headingXl.fontSize,
      marginBottom: spacing.large
    },
    [breakpointMixins.xl]: {
      fontSize: "4.474rem"
    }
  },
  arrow: {
    display: "none",
    maxWidth: 50,
    [breakpointMixins.md]: {
      display: "block"
    }
  },
  content: {
    display: "flex",
    flexDirection: "column",
    paddingTop: spacing.large,

    [breakpointMixins.lg]: {
      marginBottom: spacing.large
    },
    [breakpointMixins.xl]: {
      paddingTop: 0,
      justifyContent: "center"
    }
  }
};
