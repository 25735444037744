import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList, {
  GraphQLError
} from "../components/graphql-error-list";
import SEO from "../components/SEO";
import Layout from "../components/Layout";
import EpisodeList from "../components/EpisodeList";
import HomeHero from "../components/HomeHero";
import { AuthorProperties } from "../interfaces/blogPost";
import { ChildImageSharp } from "../interfaces/images";
import CTASection from "../components/CTASection";
import LinkCTAs from "../components/LinksCTAs";
import HelenCard from "../components/HelenCard";

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings {
      title
      description
      keywords
    }
    blogTile: file(relativePath: { eq: "blogTile.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
export interface SiteInterface {
  title: string;
  description: string;
  keywords: Array<string>;
}

interface IndexPageProps {
  data?: {
    site: SiteInterface;
    author: AuthorProperties;
    helenImage: ChildImageSharp;
    blogTile: ChildImageSharp;
  };
  errors: Array<GraphQLError>;
}

const IndexPage: React.FC<IndexPageProps> = ({ data, errors }) => {
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const { site, blogTile } = data || {};

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <>
      <SEO
        title={site.title}
        description={site.description || ""}
        keywords={site.keywords}
      />
      <HomeHero />
      <EpisodeList
        title="The podcast"
        displayBySeason={true}
        defaultSeason={1}
      />
      <HelenCard />
      <CTASection
        title="The blog"
        image={blogTile}
        content={`The lowdown on lumps and leaks and words of wisdom from the experts who live and breathe pelvic floors.`}
        cta={{
          href: "/blog",
          content: "Read the blog"
        }}
        alignment="right"
      />
      <LinkCTAs />
    </>
  );
};

export default IndexPage;
